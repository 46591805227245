






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      item: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: `Quản lý tài khoản ĐVVC - ${item.name}`,
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            code: {
              text: "Mã",
              sortable: true,
            },
            description: {
              text: "Mô tả",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "shippingCompanyId",
              operator: "equal_to",
              value: this.context().$route.params.shippingCompanyId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("shippingCompanyAccounts", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.shippingCompanyId = dataTable.context().$route.params.shippingCompanyId;
              return await coreApiClient.call("shippingCompanyAccounts", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "shippingCompanyAccounts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("shippingCompanyAccounts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              ConstantSourceNode: {
                attrs: {
                  label: "Mã",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              senderName: {},
              senderMobile: {},
              senderPostCode: {},
              senderProvince: {},
              senderCity: {},
              senderWard: {},
              senderAddress: {},
              smsSenderName: {},
            },
            insertForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "code",
                  "description",
                  "senderName",
                  "senderMobile",
                  "senderPostCode",
                  "senderProvince",
                  "senderCity",
                  "senderWard",
                  "senderAddress",
                  "smsSenderName",
                ],
              },
            },
            editForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "code",
                  "description",
                  "senderName",
                  "senderMobile",
                  "senderPostCode",
                  "senderProvince",
                  "senderCity",
                  "senderWard",
                  "senderAddress",
                  "smsSenderName",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("shippingCompanies", "findById", {
      id: this.$route.params.shippingCompanyId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
